import React from 'react';
import { useBi, useCurrencyFormatter } from '@wix/yoshi-flow-editor';
import { ToastProvider } from '../ToastContext/ToastProvider';
import { IsStepHiddenProvider } from '../IsStepHidden/IsStepHiddenProvider';
import { LoadingProvider } from '../Loading/LoadingContext';
import { FullscreenProvider } from '../Fullscreen/FullscreenProvider';
import { VIEW_MODE } from '../../config/constants';
import {
  memberWebAppScreenOpen,
  memberWebWidgetShown,
} from '@wix/bi-logger-challenges-member-web/v2';
import {
  FCWithChildren,
  setCurrencyFormatter,
} from '@wix/challenges-web-library';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { getCountryCode } from '../../selectors/getCountryCode';
import { IMainProvidersProps } from './IMainProvidersProps';
import { ControllerProvider } from './controllerContext';

export const MainProviders: FCWithChildren<IMainProvidersProps> = (props) => {
  const { Component } = props;
  const bi = useBi();
  const countryCode = getCountryCode(props.regionalSettings);
  const currencyFormatter = useCurrencyFormatter({
    country: countryCode,
  });

  setCurrencyFormatter(currencyFormatter);

  React.useEffect(() => {
    bi?.report?.(memberWebAppScreenOpen({}));

    if (props.host?.viewMode === VIEW_MODE.Site) {
      const now = Date.now();

      bi?.report?.(
        memberWebWidgetShown({
          timeSpent: Math.max(now - props.startTimeOfRender, 0),
          itemsCount: props.challengesListData?.totalCount || 1,
        }),
      );
    }
  }, []);

  return (
    <ControllerProvider value={props}>
      <ToastProvider
        toastMessage={props.toastMessage}
        clearMessage={props.clearMessage}
      >
        <IsStepHiddenProvider
          isStepHidden={props.isStepHidden}
          isVisibleStepLockedForComplete={props.isVisibleStepLockedForComplete}
        >
          <LoadingProvider
            showLoader={props.showLoader}
            loadingStatus={props.loadingStatus}
            hideLoader={props.hideLoader}
            isLoading={props.isLoading}
          >
            <FullscreenProvider toggleFullscreen={null} isFullscreen={false}>
              <FormViewerContext {...(props as any)}>
                <Component {...props} />
              </FormViewerContext>
            </FullscreenProvider>
          </LoadingProvider>
        </IsStepHiddenProvider>
      </ToastProvider>
    </ControllerProvider>
  );
};

MainProviders.displayName = 'ChallengesProviders';

export function withProviders(ProvidedComponent) {
  return function (props: any) {
    return <MainProviders {...props} Component={ProvidedComponent} />;
  };
}
