import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';

import { IPaymentSettings } from './Settings/paymentSettings/paymentSettings.types';

export const paymentPageSettings = createSettingsParams<IPaymentSettings>({
  displayTermsLink: {
    getDefaultValue() {
      return false;
    },
  },
  termsLink: {
    type: SettingsParamType.Text,
    getDefaultValue() {
      return '';
    },
  },
});

export default paymentPageSettings;
