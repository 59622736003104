import React from 'react';
import flatten from 'lodash/flatten';

import {
  IIsStepHiddenContext,
  IsStepHiddenContext,
} from './IsStepHiddenContext';
import { useSections } from '../ParticipantSections/ParticipantSectionsContext';
import utils from '../../components/ParticipantPage/Widget/views/utils';
import { useParticipantSteps } from '../ParticipantStepsDataProvider/ParticipantStepsContext';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useControllerData } from '../main/controllerContext';
import { ParticipantStep } from '../../types/v3Types';
// todo use only on the participant page
export const IsStepHiddenProvider: FCWithChildren<IIsStepHiddenContext> = (
  props,
) => {
  const { challengeData } = useControllerData();
  const { listParticipantSections = [] } = useSections();
  const { participantSteps } = useParticipantSteps();

  const isSections = listParticipantSections.length;
  const flatStepsList = isSections
    ? flatten<ParticipantStep>(
        listParticipantSections.map((section) => section.steps || []),
      )
    : participantSteps?.steps || [];

  return (
    <IsStepHiddenContext.Provider
      value={{
        isStepHidden: (step: ParticipantStep) => {
          return utils.isStepHidden(
            challengeData?.challenge,
            flatStepsList,
            step,
          );
        },
        isVisibleStepLockedForComplete: (step: ParticipantStep) => {
          return utils.isVisibleStepLockedForComplete(
            challengeData?.challenge,
            flatStepsList,
            step,
          );
        },
      }}
    >
      {props.children}
    </IsStepHiddenContext.Provider>
  );
};
